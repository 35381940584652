@use '/styles/mixin/fonts.module.scss' as *;
.drive-enquiry-form {
  &__wrapper {
    @apply my-0;
    @apply min-h-72;
    @apply max-w-screen-lg;
    @apply m-auto;
    @apply z-10;
    @screen md {
      @apply my-8;
    }
  }
  &__close-btn-wrapper {
    @apply flex;
    @apply justify-end;
    @apply items-center;
    @apply text-white;
    @apply h-14;
    @apply pr-2;
    //allow extend outside of container responsively, while keeping within the viewport.
    //it does not change the parent container's width, so modal overlay is not blocked.
    margin-right: calc(50% - 50vw);
    //setting static margin with the screen size has enough space.
    @media (min-width: 1140px) {
      @apply -mr-14;
    }
  }
  &__deal-info-wrapper {
    @apply bg-blue-darkest;
    @apply px-4;
    @apply py-2;
    @screen md {
      @apply px-8;
    }
  }

  &__deal-info {
    @include font-standfirst('mobile');
    @apply text-white;
    @apply m-0;
    @screen md {
      @include font-heading('desktop', 'h3');
    }
  }
  &__sucess-wrapper {
    @apply bg-blue-dark;
    @apply min-h-72;
    @apply pt-10;
    @apply pb-16;
    @apply text-white;
    @apply flex;
    @apply flex-col;
    @apply justify-center;
    @apply items-center;
    @apply pt-4;
    @apply max-w-none;
    @screen md {
      @apply min-w-820;
    }
  }
  &__sucess-icon-wrapper {
    @apply p-2;
    @apply mb-4;
  }
  &__sucess-icon {
    @apply fill-current;
    @apply text-teal-light;
    @apply text-4xl;
  }

  &__enquiry-sent-heading {
    @apply text-3.5xl;
    @apply px-4;
    @apply text-center;
    @screen md {
      @apply text-2xl;
    }
  }
  &__enquiry-close-btn {
    @apply cursor-pointer;
    display: none;
  }

  &__container {
    @apply bg-blue-dark;
    @apply px-5;
    @apply py-5;
    @screen md {
      @apply px-8;
    }
  }

  &__heading {
    @include font-text('mobile', 'medium');
    @apply w-full;
    @apply text-white;

    @apply mt-0;
    @screen md {
    }
  }
  &__section {
    @apply flex;
    @apply flex-col;
    @apply mt-5;
    @apply relative;
    @screen md {
      @apply flex-row;
      @apply items-start;
    }
  }
  &__dropdown {
    @apply text-white;
    @apply text-14px;
    @apply font-semibold;
    @apply mb-4;
    box-shadow: none;

    &.disabled {
      opacity: 1;
    }
    @screen md {
      @apply mb-10;
      @apply max-w-64;
    }
    &:global(.dropdown--white) {
      @apply text-black;
      @apply bg-white;
      @apply border-black;
      &.disabled {
        @apply text-black;
      }
      [class*='dropdown_drive-dropdown__heading'] {
        @apply border-0;
        @apply mb-0;
      }
    }
  }

  &__dropdown-item {
    @apply px-4;
    @apply py-3;
  }

  &__fields-container {
    @apply flex;
    @apply flex-col;
    @apply flex-wrap;
    @apply items-center;

    // Style for form autocomplete field, to avoid white background
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 50px #2960c5 inset;
      -webkit-text-fill-color: white;
      background-color: #2960c5;
      background-clip: content-box;
    }

    @screen md {
      @apply w-11/12;
      margin-left: 3.125rem;
    }

    @media (min-width: 821px) {
      @apply flex-row;
    }

    @screen lg {
      @apply flex-row;
      @apply items-end;
    }

    :global(.errors) {
      @apply w-full;
    }

    :global(.btn-wrapper) {
      @apply w-full;
      @apply flex;
      @apply justify-center;
      @screen md {
        @apply justify-end;

        :global(.btn) {
          width: 48%;
        }
      }
      &:global(.submit) {
        display: block;
        width: 100%;

        @screen md {
          display: inline-block;
          @apply justify-end;
          width: 50%;
          @apply mt-0;
        }

        button {
          width: 100%;
          @apply text-blue-dark;
          @apply bg-white;
        }

        :global(.error-btn) {
          @apply text-white;
          @apply bg-coral-normal;
          border: unset;
          opacity: 1;

          &:hover {
            @apply text-white;
            @apply bg-coral-normal;
            opacity: 1;
          }
        }
      }
    }

    &:global(.equire-form--white) {
      :global(.btn-wrapper) {
        &:global(.submit) {
          button {
            width: 100%;
            @apply text-white;
            @apply bg-blue-normal;
            @apply border-0;
          }
          :global(.error-btn) {
            @apply text-white;
            @apply bg-coral-normal;
            border: unset;
            opacity: 1;

            &:hover {
              @apply text-white;
              @apply bg-coral-normal;
              opacity: 1;
            }
          }
        }
      }
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 50px white inset;
        @apply bg-white;
        @apply text-black;
        /* Change the color to your own background color */
        -webkit-text-fill-color: black;
        background-clip: content-box;
      }
    }
  }

  &__fields {
    @apply w-full;
    @apply flex;
    @apply flex-wrap;
    @apply justify-between;
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    @apply not-italic;
    @apply font-normal;
    line-height: 20px;
    &__inline {
      @screen lg {
        @apply pb-5;
      }
    }

    &:global(.enquire-form__fields--white) {
      input::placeholder {
        @apply text-black;
      }
      textarea {
        @apply text-base;
        @apply font-semibold;
      }
      textarea::placeholder {
        @apply text-black;
      }

      :global(.label) {
        input[type='text'] {
          @apply border-0;
        }
        input[type='checkbox'] {
          &:checked + :global(.checkbox) {
            @apply bg-white;

            &:after {
              @apply border-blue-dark;
            }
          }
        }
      }
    }

    :global(.label) {
      @apply mt-0;
      @apply mb-4;

      @screen md {
        width: 49%;
        margin-bottom: 14px;
      }

      @apply text-white;

      :global(.error) {
        p {
          @apply text-coral-normal;
          @apply text-12px;
          @apply m-0;
        }
      }

      &.hidden {
        display: none;
      }

      a {
        @apply text-white;
        @apply underline;

        &:hover {
          @apply text-white;
        }
      }

      input {
        @apply text-base;
        @apply font-semibold;
      }

      &:global(.email),
      &:global(.comments) {
        @apply w-full;
      }

      input[type='checkbox'] {
        &:checked + :global(.checkbox) {
          @apply bg-white;

          &:after {
            content: '';
            @apply absolute;
            top: 0.25rem;
            left: 0.25rem;
            @apply bottom-0;
            @apply border-l-2;
            @apply border-b-2;
            @apply border-blue-dark;
            transform: rotate(-51deg);
            width: 0.75rem;
            height: 0.375rem;
          }
        }
      }
    }

    :global(.enquiry-comments) {
      height: 9.4375rem; //151px
      @screen lg {
        height: 7.5625rem; //121px
      }
    }

    :global(.checkboxes) {
      @apply w-full;
      @apply flex;
      @apply flex-col;
      @apply items-start;

      h3 {
        @apply text-white;
        @include font-text('mobile', 'medium');

        @screen lg {
          @include font-text('desktop', 'medium');
        }
      }

      @screen md {
        width: 29%;
        @apply absolute;
        @apply left-0;
        @apply bottom-0;
      }

      :global(.label) {
        @apply mb-4;

        @screen md {
          @apply mb-3;
        }

        @apply w-auto;
      }
    }

    [class^='checkbox_drive-form-checkbox__input-wrapper'] {
      @apply items-start;
    }
  }

  &__form-submit-error {
    @include font-caption('desktop', 'bold');
    @apply pr-1;
    @apply mb-5;
    @apply mr-px;
    @apply text-coral-normal;
  }
}

.drive-enquiry-form__message {
  &__wrapper {
    @apply py-10;
    @apply text-black;
    @apply flex;
    @apply flex-col;
    @apply justify-center;
    @apply items-center;
    @apply mt-4;
  }
  &__icon-wrapper {
    @apply rounded-full;
    @apply border-teal-light;
    @apply border-4;
    @apply p-2;
    @apply mb-4;
  }
  &__icon {
    @apply fill-current;
    @apply text-black;
    @apply text-4xl;
  }
  &__heading {
    @apply text-4xl;
    @apply text-center;
  }
}

.drive-enquiry-form__success-message {
  @apply rounded;
  @apply p-3;
  @apply text-sm;
  @apply bg-green-200;
  @apply text-black;
}
.drive-enquiry-form__error-message {
  @apply rounded;
  @apply p-3;
  @apply text-sm;
  @apply bg-coral-light;
  @apply text-white;
}
.enquiry-agree-label {
  font-size: 0.5625rem; // text-9px doesnt work in prod

  @screen lg {
    width: 50%; // md:w-6/12 doesnt work as tailwind class in prod
  }
}

.checkbox-header {
  @apply text-white;
  @apply pb-5;
}

.enquiry-button {
  transition: none;
}

.drive-showrooms-inline-enquiry {
  @apply max-w-[51rem];
}
