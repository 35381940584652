.drive-author-avatar {
  &__container { 
    @apply rounded-full;
    @apply bg-gray-100;
    @apply m-0;
  }
  &__xlarge { 
    @apply w-16;
    @apply h-16;
  }

  &__large {
    @apply w-12;
    @apply h-12;

  }

  &__medium { 
    @apply w-10;
    @apply h-10;

  }

  &__small {
    width: 1.563rem;
    height: 1.563rem;
  }

  &__img {
    @apply object-cover;
    @apply rounded-full;
  }

  &__link { 
    @apply cursor-pointer;
  }
}
