.drive-coverBlock {
  :global(.wp-block-cover) {
    :global(.has-background-dim-100.has-background-dim:not(.has-background-gradient)::before) {
      @apply opacity-100;
    }
  }
  :global(.no-margin) {
    @apply ml-[-1.25rem];
    @apply w-screen;
    @screen md {
      @apply w-full;
      @apply ml-0;
    }
  }
}
