@use '/styles/mixin/fonts.module.scss' as *;

.drive-cfs-form-buttons {
  &__button {
    @apply w-full;
    @apply flex;
    @apply justify-center;
    @include font-button;
    :global(.btn) {
      @apply w-full;
      @apply bg-blue-dark;
      @apply text-white;
    }
    :global(.error-btn) {
      @apply w-full;
      @apply text-white;
      @apply bg-coral-normal;
      border: unset;
      @apply opacity-100;

      &:hover {
        @apply text-white;
        @apply bg-coral-normal #{!important};
        @apply opacity-100;
      }
    }
  }
}
